import { BackendErrorCodesEnum } from './backendErrorCodes';
import { CredentialEnum } from './session';

export class LoginResponseBase {
  type: 'LoginResponse' | 'LoginNoUserResponse' | 'LoginMultiUserResponse';
  code: BackendErrorCodesEnum;
}
export class LoginResponse extends LoginResponseBase {
  mfaMethods?: CredentialEnum[];
  success?: boolean;
  dotrustprompt?: boolean;
  notRegistered?: any;
  phoneHint?: string;
  // code: BackendErrorCodesEnum;
  // id_token: any;
  identityId: string;
  provider: CredentialEnum;
  // accessToken: any;
  stamp: number;
}

export class LoginNoUserResponse extends LoginResponseBase {
  id_token: any;
  identityId: string;
  provider: CredentialEnum;
  accessToken: any;
  stamp: number;
}

export class LoginMultiUserResponse extends LoginResponseBase {
  id_token: any;
  provider: CredentialEnum;
  identityId: string;
  accessToken: any;
  stamp: number;
  userList: string[];
}
