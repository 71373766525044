export type MongoId = string | any;

export enum ChatChannelType {
  MEETING_CHANNEL = 'MEETING_CHANNEL',
  ORGANIZATION_CHANNEL = 'ORGANIZATION_CHANNEL',
  TASK_CHANNEL = 'TASK_CHANNEL',
}

export type IChatMessage = {
  channelId: MongoId;
  organizationId: MongoId;
  fromUserId: MongoId;
  receivedAt: Date;
  editedAt: Date;
  message: string;
  read: MongoId;
  editedMessageHistory: string[];
  replyToMessageId: MongoId;
  mentionedUserIds: MongoId[];
  isDeleted: boolean;
  deletedAt: Date | number;
  deletedByUser: MongoId;
};

export type IChannel = {
  _id: MongoId;
  id: string;
  chatChannelType: ChatChannelType;
  meetingId: MongoId;
  taskId: MongoId;
  organizationId: MongoId;
  usersToLastSeenMessage: any;
};
