import { ChangeDetectorRef, Component } from '@angular/core';
import { ModuleInfoService } from '../services/module-info.service';

import { Observable } from 'rxjs';
import { Input } from '@angular/core';
import { inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'orgbrain-info-box-open-button',
  template: `
    <mat-icon
      mat-icon-button
      class="module-info-open-button"
      (click)="open()"
      [ngClass]="{ 'd-none': !amIClosed }"
      [inline]="true"
      >help_outline</mat-icon
    >
  `,
  styles: [
    `
      .module-info-open-button {
        font-size: 1.3rem !important;
        opacity: 0.5;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }

      .module-info-open-button:hover {
        opacity: 1;
      }
    `,
  ],
})
export class InfoBoxOpenButtonComponent implements OnInit {
  moduleInfoService: ModuleInfoService = inject(ModuleInfoService);

  @Input() moduleId: string;

  closed$: Observable<boolean>;
  amIClosed: boolean;
  constructor(private changeDetector: ChangeDetectorRef) {}
  ngOnInit() {
    this.closed$ = this.moduleInfoService.closedX(this.moduleId);
    this.closed$.pipe(untilDestroyed(this)).subscribe((val) => {
      // setTimeout is to avoid changed after checked error
      setTimeout(() => {
        this.amIClosed = val;
        this.changeDetector.detectChanges();
      }, 0);
    });
  }

  open() {
    this.moduleInfoService.open(this.moduleId);
    this.changeDetector.detectChanges();
  }
}
