import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orgbrain-orgbrain-help',
  templateUrl: './orgbrain-help.component.html',
  styleUrls: ['./orgbrain-help.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          'max-height': '100px',
          opacity: 1,
          padding: '4px 4px',
        })
      ),
      state(
        'closed',
        style({
          'max-height': '0px',
          opacity: 0.0,
          padding: '0px 4px',
        })
      ),
      transition('open => closed', [animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
      transition('closed => open', [animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
    ]),
  ],
})
export class OrgbrainHelpComponent implements OnInit {
  @Input() orgbrainHelp: string;
  @Input() orgbrainUrl: string;
  showingHelp = false;
  constructor() {}

  ngOnInit(): void {
    null;
  }
}
