import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SharedUtilService } from '../util.service';
import { backendErrorCodes } from '@orgbrain/lib-data';

export class SnackRefProxy {
  activeToast: ActiveToast<any> | any;
  constructor(activeToast: ActiveToast<any>) {
    this.activeToast = activeToast;
  }
  dismiss(): void {
    this.activeToast.toastRef.close();
  }
  onAction(): Observable<void> {
    return this.activeToast.onAction;
  }
}

@Injectable({ providedIn: 'root' })
export class Snack2ToastrService {
  constructor(private toastr: ToastrService, public util: SharedUtilService) {}

  // Define a new open function that maps to Toastr's success function

  open(
    message: string | any,
    buttonText = null,
    config = null,
    icon: 'success' | 'info' | 'warning' | 'error' = 'info' ///actully any mat-icon but lets not get too exicited.
  ): SnackRefProxy {
    const toasts = this.toastr.toasts;

    console.log('toasts', toasts);

    message = this.util.sensibleMessage(message);
    const options = {
      tapToDismiss: false,
      closeButton: true,
      disableTimeOut: !config?.duration,
      timeOut: config?.duration,
      icon: icon,
      buttonText,
      extendedTimeOut: 0,
      enableHtml: config?.enableHtml,
    };

    console.log(this.toastr.toasts);

    return new SnackRefProxy(this.toastr.show(message, null, options));
  }

  //  Custom backend error snackbar

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  backendError(error: any, lang = 'en'): SnackRefProxy {
    const options = {
      tapToDismiss: false,
      closeButton: true,
      disableTimeOut: true,
      icon: 'error',
      buttonText: 'OK',
      extendedTimeOut: 0,
    };

    const toasts = this.toastr.toasts;

    console.log('toasts', toasts);

    const code = error.code;
    let message = error || 'An error occurred';
    if (code) {
      message = backendErrorCodes[code][lang];
    } else {
      message = this.util.sensibleMessage(message);
    }

    return new SnackRefProxy(this.toastr.show(null, message, options));
  }
}
