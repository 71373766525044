<div class="orgbrain-style" style="width: 100%">
  <div class="short-page" style="width: 100%; margin: auto">
    <!-- <mat-card
      appearance="outlined"
      class="w-100 border-box orgbrain-box-shadow-2 padding-standard-front-page"
      style="font-size: 14px; margin-bottom: 16px"
    > -->
    <div class="center mb-20 mt-20" style="width: 200px; margin: auto">
      <orgbrain-orgbrain-logo></orgbrain-orgbrain-logo>
    </div>

    <h1 class="title-text-1">404</h1>

    <p class="body-text-1">
      {{ texts.notfound[locale] }}
    </p>

    <button mat-icon-button class="mt-20" (click)="goHome()" style="width: 40px; margin: auto">
      <mat-icon class="icon">home </mat-icon>
    </button>
  </div>
</div>
