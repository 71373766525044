import { OnInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ThousandseparatordisplayPipe } from '../thousandseparatordisplay.pipe';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css'],
})
export class TreeViewComponent implements OnChanges {
  @Input() depth: number = 0;
  @Input() data: any;
  @Input() name: string;
  @Input() expanded = false;
  // functions to create content of a scalar key
  @Input() dynamicKeys = {};
  // sub objects if appropriate
  children = null;
  // scalar value
  val = null;
  canExpand: boolean;
  display: string;

  async expand() {
    this.expanded = !this.expanded;
    if (this.dynamicKeys[this.name] && !this.children) {
      if (this.expand && this.val) {
        const ret = await this.dynamicKeys[this.name](this.val);
        if (ret) {
          this.data = ret;
          this.children = Object.keys(this.data);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.depth = +this.depth;
    if (!(this.data instanceof Object)) {
      this.val = this.data;
      this.canExpand = !!this.dynamicKeys[this.name];
      this.display = '';
      //   for (let i = 0; i < this.depth; i++) {
      //     this.display += '&nbsp;';
      //   }

      this.display = `<b>${this.name}</b>:\t${this.val}`;

      // console.log(this.name + ": " + this.val)
    } else {
      this.children = Object.keys(this.data);
      this.canExpand = true;
      this.display = this.name;
    }
  }
}
