<p>component-container works!</p>

<!-- <ng-template #viewContainerRef></ng-template> -->
<!-- cdkDragBoundary=".orgbrain-sidenav-container" -->
<div [ngClass]="{ portalContent: true }" cdkDrag>
  <div cdkDragHandle>
    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
      pa
      <path
        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
      ></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>
  <ng-template [cdkPortalOutlet]="templatePortal"></ng-template>
</div>

<ng-template #templatePortalContent>
  <div class="potalInner">
    <button mat-icon-button color="primary" class="close-button close-pos" (click)="(null)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>
