/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'orgbrain-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
})
export class LangSelectComponent implements OnInit {
  @Input() lang: string;
  @Output() selected = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { }

  selectLanguage(lang) {
    this.lang = lang;
    this.selected.emit(lang);
  }
}
