import { Directive, ElementRef, OnInit } from '@angular/core';
import {
  StylePreferenceEnum,
  StylePreferencesService,
} from '../services/style-preferences.service';

@Directive({
  selector: '[orgbrainStyle]',
})
export class StyleDirective implements OnInit {
  constructor(private element: ElementRef, private stylePreferences: StylePreferencesService) {
    // console.log('orgbrainStyle');
  }

  ngOnInit() {
    if (this.stylePreferences.getPreference(StylePreferenceEnum.wrapTabLabels)) {
      const labelsList = this.element.nativeElement.getElementsByClassName('mat-tab-labels');

      if (labelsList.length === 1) {
        const classList = labelsList[0].classList;
        classList.add('orgbrain-mat-tab-labels-wrap');
      }
    }
  }
}
