import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TitleBarButton } from './title-bar-button';

@Component({
  selector: 'orgbrain-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent implements OnInit {
  @Input() headerSvg: string;
  @Input() header: string;
  @Input() headerTooltip: string;
  @Input() buttons: TitleBarButton[];
  @Input() size = 1; //  1 is largest 2 smaller etc
  @Input() noleftmargin;
  @Input() norightmargin;
  @Input() helpLink;
  @Input() helpTooltip;
  @Input() skipItemsCenterClassOnRightPartOfBar = false;
  @Input() avoidPT5 = false;
  @Input() closeButton = false;
  @Input() avoidEllipsis = false;
  @Output() closeEvent = new EventEmitter();

  ngOnInit() {
    this.size = +this.size;
  }
}
