import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'orgbrain-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
})
export class DialogTitleComponent {
  @Input() dialogRef;
  @Input() title;
  @Input() helpurl;
  @Input() closeIcon;
  @Input() icon;

  @Input() helpText;
  @Input() helpTextId;
  @Input() padding_bottom = '16px';
  @Input() beginClosedState = false;
  @Input() titleCss = 'title';
  @Output() closeClick = new EventEmitter();

  closeClicked() {
    this.closeClick.emit();

    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
