export function formatDateTimeLocale({
  dd,
  timeZone,
  lang,
}: {
  dd: Date;
  timeZone: string;
  lang: string;
}) {
  if (!dd) throw new Error('formatDateTimeLocale: dd is required');
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return new Intl.DateTimeFormat(lang, options).format(new Date(dd));
}

/*

options = {
    timeZone:"Europe/Oslo",
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

options = {
    timeZone:"Europe/London",
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };


  lang = "nb";
new Intl.DateTimeFormat(lang, options).format(new Date());
*/
