import { Injectable } from '@angular/core';
import { LocaleService } from '../locale-service';

export enum StylePreferenceEnum {
  wrapTabLabels = 'wrapTabLabels',
  newDesign = 'newDesign',
  showDebug = 'showDebug',
}

export const StylePreferencesLanguage: {
  [key in StylePreferenceEnum]: { en: string; nb: string };
} = {
  [StylePreferenceEnum.wrapTabLabels]: {
    en: 'Wrap tab labels',
    nb: 'Bryt linjer i faner',
  },
  [StylePreferenceEnum.newDesign]: {
    en: 'New design',
    nb: 'Nytt design',
  },
  [StylePreferenceEnum.showDebug]: {
    en: 'DebugInfo',
    nb: 'DebugInfo',
  },
};

@Injectable({
  providedIn: 'root',
})
export class StylePreferencesService {
  private prefs: { [key in StylePreferenceEnum]: boolean } = {
    wrapTabLabels: false,
    newDesign: false,
    showDebug: false,
  };

  constructor(public locale: LocaleService) {
    const prefsString = localStorage.getItem('stylePreferences');
    if (prefsString) {
      this.prefs = JSON.parse(prefsString);
    }
  }

  getPreference(key: StylePreferenceEnum) {
    return this.prefs[key];
  }

  setPreference(key: StylePreferenceEnum, value: boolean) {
    this.prefs[key] = value;
    localStorage.setItem('stylePreferences', JSON.stringify(this.prefs));
  }

  getPrefLabel(key: StylePreferenceEnum) {
    return (
      StylePreferencesLanguage[key][this.locale.getLanguage()] ||
      StylePreferencesLanguage[key]['en']
    );
  }
}
