<!-- {{data}}|{{name}}|{{val}} -->
<div style="display: flex" class="canpress" (click)="expand()">
  <span [style.width]="12 * depth + 'px'"></span>
  <mat-icon *ngIf="canExpand"> {{ expanded ? 'arrow_drop_down' : 'arrow_right' }}</mat-icon>
  <span [innerHTML]="display"> </span>
</div>

<div *ngIf="expanded" style="display: flex; flex-direction: column">
  <ng-container *ngFor="let d of children">
    <app-tree-view
      [data]="data[d]"
      [name]="d"
      [depth]="depth + 1"
      [dynamicKeys]="dynamicKeys"
    ></app-tree-view>
  </ng-container>
</div>
