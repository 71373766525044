/*
 *  Copyright 2010-2016 FinTech Neo AS ( fintechneo.com )- All rights reserved
 */

import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoParams } from './info';
import { OrgbrainLayoutService } from '../orgbrain-layout.service';

@Component({
  templateUrl: 'multichoicedialog.component.html',
  styleUrls: ['dialog.component.scss'],
})
export class MultiChoiceDialogComponent {
  trustedHtml: SafeHtml;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InfoParams,
    public dialogRef: MatDialogRef<MultiChoiceDialogComponent>,
    sanitizer: DomSanitizer,
    public layout: OrgbrainLayoutService
  ) {
    this.trustedHtml = sanitizer.bypassSecurityTrustHtml(data.message);
  }

  select(option) {
    this.dialogRef.close(option);
  }
  getColor(option) {
    if (!option.style) {
      return option.key === 'CANCEL' ? 'secondary' : 'primary';
    } else {
      return option.style === 'primary' ? 'primary' : 'secondary';
    }
  }

  getStyle(option) {
    switch (option.style) {
      case 'primary':
        return '';
      case 'outline':
        return 'border:1px solid  #B0BEC5;color:#0097a7';
        return '';
      case 'blank':
        if (!this.layout.mobile) {
          return 'border:none,min-width:0px !important;color:#0097a7;margin-right: -5px;';
        } else {
          return 'border:none,min-width:0px !important;color:#0097a7;';
        }
    }
  }
}
