import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'orgbrain-dialog-text-prompt',
  templateUrl: './support-note.component.html',
  styleUrls: ['./support-note.component.scss'],
})
export class TextPromptComponent {
  value = '';
  constructor(
    public dialogRef: MatDialogRef<TextPromptComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ok() {
    this.dialogRef.close(this.value);
  }
}
