// Stuff that paul could not cope with has been put here

import {
  CompanyAcronymsEnum,
  Role,
  RoleContext,
  rolesPriorityArray,
  TranslationTextMap,
} from '@orgbrain/roles';

import { localeLessTranslator } from './services/optional-translation.helper';
import { textMappings } from './services/texts/text-mappings';

export type Role2DateMap = { [key in Role]?: Date };

export const translateRole = function ({
  key,
  locale,
  lowercase,
  orgType,
  translateTexts,
}: {
  key: string;
  locale: string;
  lowercase?: boolean;
  orgType?: CompanyAcronymsEnum;
  translateTexts?: TranslationTextMap;
}): string {
  orgType = orgType || CompanyAcronymsEnum.AS;

  const translatedRole = localeLessTranslator.translateText(key, locale, orgType, translateTexts);

  return translatedRole.toUpperCase() === 'CEO'
    ? 'CEO'
    : lowercase
    ? translatedRole.toLocaleLowerCase()
    : translatedRole;
};

export const getUIAndEnumerationString = function (
  translatedRoles: string[],
  locale: string
): string {
  let userFriendlyRolesEnumeration = '';

  if (translatedRoles.length > 1) {
    // The first part concatenated with commas ('Administrator, board member')
    userFriendlyRolesEnumeration = translatedRoles
      .slice(0, translatedRoles.length - 1) // exclude last array element
      .join(', ');

    // The last "and-part" (' and observer')
    const andText = textMappings['and'][locale] || textMappings['and']['en'];
    userFriendlyRolesEnumeration += ` ${andText} ${translatedRoles[translatedRoles.length - 1]}`;
  } else if (translatedRoles.length === 1) {
    userFriendlyRolesEnumeration = translatedRoles[0];
  }

  return userFriendlyRolesEnumeration;
};

/**
 * Returns a string on the form 'Administrator, board member and observer'.
 * @param roleContext Used to only include roles for a specific context.
 */
export const getUserFriendlyRolesEnumeration = function ({
  roles,
  locale,
  roleContext,
  uppercaseFirstLetter,
  orgType,
  translateTexts,
}: {
  roles: Role[];
  locale: string;
  roleContext?: RoleContext;
  uppercaseFirstLetter?: boolean;
  orgType?: CompanyAcronymsEnum;
  translateTexts?: TranslationTextMap;
}): string {
  if (typeof uppercaseFirstLetter !== 'boolean') {
    uppercaseFirstLetter = true;
  }

  // Get the roles as an array of strings translated etc
  const rolesAsArr = getHostileRolesEnumeration({
    roles,
    locale,
    roleContext,
    orgType,
    translateTexts,
  });

  if (rolesAsArr.length === 0) {
    return '';
  }

  let userFriendlyRolesEnumeration = getUIAndEnumerationString(rolesAsArr, locale);

  // First letter uppercase
  if (uppercaseFirstLetter && userFriendlyRolesEnumeration.length > 0) {
    userFriendlyRolesEnumeration =
      userFriendlyRolesEnumeration.charAt(0).toUpperCase() + userFriendlyRolesEnumeration.slice(1);
  }

  return userFriendlyRolesEnumeration;
};

/**
 * Returns a string on the form 'Administrator, board member , observer'.
 * @param roleContext Used to only include roles for a specific context.
 */
export const getHostileRolesEnumeration = function ({
  roles,
  locale,
  roleContext,
  orgType,
  translateTexts,
}: {
  roles: Role[];
  locale: string;
  roleContext?: RoleContext;
  orgType?: CompanyAcronymsEnum;
  translateTexts?: TranslationTextMap;
}): string[] {
  const roleOrderForUIEnumeration = rolesPriorityArray;

  if (!roles) return [];

  if (roleContext) {
    roles = roles.filter((role) => {
      const hasEnumerationEnabled = rolesForRoleContext.get(roleContext).includes(role);
      const isEnabledByFactOfCustomTransaltion = translateTexts && translateTexts[role];
      // When custom role translation is enabled, Dag required to also include it in translation at 24.03.2023
      return hasEnumerationEnabled || isEnabledByFactOfCustomTransaltion;
    });
  }

  if (roles.length === 0) return [];

  roles.sort((a, b) => roleOrderForUIEnumeration.indexOf(a) - roleOrderForUIEnumeration.indexOf(b));

  const translatedRoles = roles.map((role) =>
    translateRole({ key: role, locale, lowercase: true, orgType, translateTexts })
  );

  return translatedRoles;
};

//  TODO    We should not do this . . . . use persmisions.
const rolesForRoleContext: Map<RoleContext, Role[]> = new Map([
  [
    RoleContext.MEETING_APP, //  ACCESS_TO_GA_MEETINGS   ||   ACCESS_TO_BOARD_MEETINGS:
    [
      Role.ADMIN,
      Role.CHAIR,
      Role.BOARDMEMBER,
      Role.CEO,
      Role.BOARDSECRETARY,
      Role.DEPUTYCHAIR,
      Role.DEPUTYMEMBER,
      Role.SHAREHOLDER,
      Role.OWNER,
      Role.SHAREHOLDER_PROXY,
      Role.OBSERVER,
      Role.AUDITOR,
      Role.CONTACTPERSON,
      Role.SELLER_ADMIN,
      Role.SELLER,
      Role.BUYER_ADMIN,
      Role.BUYER,
    ],
  ],
  [
    RoleContext.MEETING_PROTOCOL, // ACCESS_TO_BOARD_MEETINGS
    [
      Role.CHAIR,
      Role.BOARDMEMBER,
      Role.CEO,
      Role.BOARDSECRETARY,
      Role.DEPUTYCHAIR,
      Role.DEPUTYMEMBER,
      Role.OBSERVER,
      Role.AUDITOR,
    ],
  ],
  [
    RoleContext.GA_MEETING_PROTOCOL, //  ACCESS_TO_GA_MEETINGS
    [
      Role.CHAIR,
      Role.BOARDMEMBER,
      Role.CEO,
      Role.BOARDSECRETARY,
      Role.DEPUTYCHAIR,
      Role.DEPUTYMEMBER,
      Role.SHAREHOLDER,
      Role.OWNER,
      Role.SHAREHOLDER_PROXY,
      Role.OBSERVER,
      Role.AUDITOR,
    ],
  ],
]);
