import { SecurityGroupName } from '@orgbrain/roles';
import { CredentialEnum } from './session';

export const securityGroupNames: SecurityGroupName[] = Object.keys(
  SecurityGroupName
) as SecurityGroupName[];

export type RuleName = 'basic' | 'session_2FA' | 'session_BankID' | 'ID_and_session_2FA';

export enum Code {
  FATAL = 'FATAL',
  NO_PERMISSION = 'NO_PERMISSION',
  NEED_CREDENTIALS = 'NEED_CREDENTIALS',
}

export enum PolicyName {
  Default = 'Default',
  Admin_2FA = 'Admin_2FA',
  Medium_2FA = 'Medium_2FA',
  All_2FA = 'All_2FA',
  INSANE = 'INSANE',
  BankID_2FA = 'BankID_2FA',
}

export const hidePolicyNames = [PolicyName.INSANE, PolicyName.BankID_2FA];

export const default2FAArray = [CredentialEnum.SMS, CredentialEnum.TOTP];
export const default2FAArrayMap: { [key in CredentialEnum]?: boolean } = default2FAArray.reduce(
  (map, obj) => {
    map[obj] = true;
    return map;
  },
  {} as any
);

export const bankIdArray = [CredentialEnum.BANKID];
export const defaultIdArray = [CredentialEnum.BANKID, CredentialEnum.MANUAL];
