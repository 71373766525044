import { MediaMatcher } from '@angular/cdk/layout';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

export type StepDeclaration = {
  stepName: string;
  isStepClickable: boolean;
  stepTooltip: string;
  stepTitle: string;
  stepHint?: string;
};
@Component({
  selector: 'orgbrain-power-stepper',
  templateUrl: './power-stepper.component.html',
  styleUrls: ['./power-stepper.component.scss'],
})
export class PowerStepperComponent implements OnInit {
  @Input() stepDeclarations: StepDeclaration[] = [];
  @Input() currentStep = '';
  @Input() minified = false;
  @Output() stepClicked = new EventEmitter();
  widthOfAListItem = 100;
  mobilePortraitQuery: MediaQueryList;

  constructor(private media: MediaMatcher) {}

  ngOnInit(): void {
    this.doResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.doResize();
  }

  onClick(step: StepDeclaration) {
    if (step.isStepClickable) {
      this.stepClicked.emit(step);
    }
  }

  doResize() {
    this.mobilePortraitQuery = this.media.matchMedia('(min-width: 580px)');
    this.widthOfAListItem = this.mobilePortraitQuery.matches
      ? 100 / this.stepDeclarations.length
      : 100;
  }
}
