export const textMappings = {
  VisibleToAll: {
    en: 'Files for all Users',
    nb: 'Felles filer for alle brukere',
  },
  CorporateInformation: {
    en: 'Corporate information',
    nb: 'Selskapsinformasjon',
  },
  CompanyRegistrationCertificate: {
    en: 'Company registration certificate',
    nb: 'Registreringsdokumenter',
  },
  'By-laws': {
    en: 'By-laws',
    nb: 'Vedtekter',
  },
  RulesOfProceduresForTheBoard: {
    en: 'Rules of procedures for the board',
    nb: 'Styreinstruks',
  },
  BusinessPlans: {
    en: 'Business plans',
    nb: 'Forretningsplaner',
  },
  CompanyPresentations: {
    en: 'Company presentations',
    nb: 'Presentasjoner',
  },
  BoardAuthorizations: {
    en: 'Board authorizations',
    nb: 'Fullmakter til styret',
  },
  OwnershipAndInvestments: {
    en: 'Ownership and investments',
    nb: 'Eierskap og investeringer',
  },
  ShareRegister: {
    en: 'Shareholder Register',
    nb: 'Aksjeeierbok',
  },
  OptionAgreements: {
    en: 'Option agreements',
    nb: 'Opsjonsavtaler',
  },
  ConvertibleAgreements: {
    en: 'Convertible agreements',
    nb: 'Konvertible lån',
  },
  EquityIssues: {
    en: 'Equity issues',
    nb: 'Kapitalforhøyelser',
  },
  ShareholderAgreements: {
    en: 'Shareholder agreements',
    nb: 'Aksjonæravtaler',
  },
  FinancialInformation: {
    en: 'Financial information',
    nb: 'Finansiell informasjon',
  },
  AnnualStatements: {
    en: 'Annual statements',
    nb: 'Årsregnskap',
  },
  LoanAgreements: {
    en: 'Loan agreements',
    nb: 'Låneavtaler',
  },
  LeaseAgreements: {
    en: 'Lease agreements',
    nb: 'Leasingavtaler',
  },
  Valuations: {
    en: 'Valuations',
    nb: 'Verdivurderinger',
  },
  Budgets: {
    en: 'Budgets',
    nb: 'Budsjetter',
  },
  Reports: {
    en: 'Reports',
    nb: 'Regnskapsrapporter',
  },
  AuditorsReports: {
    en: 'Auditors reports',
    nb: 'Revisjonsrapporter',
  },
  AuditorsLetters: {
    en: 'Auditors letters',
    nb: 'Brev fra revisor',
  },
  TaxesAndFees: {
    en: 'Taxes and fees',
    nb: 'Skatter og avgifter',
  },
  Agreements: {
    en: 'Agreements',
    nb: 'Kontrakter',
  },
  Suppliers: {
    en: 'Suppliers',
    nb: 'Leverandører',
  },
  Customers: {
    en: 'Customers',
    nb: 'Kunder',
  },
  Distribution: {
    en: 'Distribution',
    nb: 'Distribusjon',
  },
  Partner: {
    en: 'Partner',
    nb: 'Partner',
  },
  Employees: {
    en: 'Employees',
    nb: 'Ansatte',
  },
  EmploymentContracts: {
    en: 'Employment contracts',
    nb: 'Ansettelsesavtaler',
  },
  EmployeesInsurance: {
    en: 'Employees Insurance',
    nb: 'Personalforsikring',
  },
  PensionArrangements: {
    en: 'Pension arrangements',
    nb: 'Pensjonsavtaler',
  },
  Unions: {
    en: 'Unions',
    nb: 'Fagforeninger',
  },
  HSE: {
    en: 'HSE',
    nb: 'HMS',
  },
  CourseAndTraining: {
    en: 'Course and training',
    nb: 'Kurs og opplæring',
  },
  IntellectualPropertyRights: {
    en: 'Intellectual property rights',
    nb: 'Immaterielle rettigheter',
  },
  Patents: {
    en: 'Patents',
    nb: 'Patenter',
  },
  LicenseAgreements: {
    en: 'License Agreements',
    nb: 'Lisensavtaler',
  },
  TradeMarks: {
    en: 'Trade marks',
    nb: 'Varemerker',
  },
  DomainNames: {
    en: 'Domain names',
    nb: 'Domenenavn',
  },
  SoftwareLicensesHeld: {
    en: 'Software licenses held',
    nb: 'Programvarelisenser innkjøpt',
  },
  SoftwareLicensesDeveloped: {
    en: 'Software licenses developed',
    nb: 'Programvarelisenser utviklet',
  },
  Insurance: {
    en: 'Insurance',
    nb: 'Forsikringer',
  },
  InsuranceOverview: {
    en: 'Insurance overview',
    nb: 'Forsikringsoversikt',
  },
  InsurancePolicies: {
    en: 'Insurance policies',
    nb: 'Forsikringspoliser',
  },
  Regulatory: {
    en: 'Regulatory',
    nb: 'Regulatoriske forhold',
  },
  Licenses: {
    en: 'Licenses',
    nb: 'Lisenser og konsesjoner',
  },
  Disputes: {
    en: 'Disputes',
    nb: 'Tvister',
  },
  CurrentDisputes: {
    en: 'Current disputes',
    nb: 'Pågående tvister',
  },
  HistoricDisputes: {
    en: 'Historic disputes',
    nb: 'Historiske tvister',
  },
  Other: {
    en: 'Internal control',
    nb: 'Internkontroll',
  },
  Environment: {
    en: 'Environment',
    nb: 'Miljø',
  },
  RiskManagementAndInternalControl: {
    en: 'Risk management and internal control',
    nb: 'Risikostyring og internkontroll',
  },
  ResearchAndDevelopment: {
    en: 'Research and development',
    nb: 'Forskning og utvikling',
  },
  DueDiligenceReports: {
    en: 'Due diligence reports',
    nb: 'Due diligence-rapporter',
  },
};
