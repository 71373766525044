import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'orgbrain-snack-mess',
  templateUrl: './snack-mess.component.html',
  styleUrls: ['./snack-mess.component.scss'],
})
export class SnackMessComponent implements OnInit {
  hasAction: boolean;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snack: MatSnackBar) {
    this.hasAction = data.action;
  }

  ngOnInit() {}

  action() {
    this.snack.dismiss();
  }
}
