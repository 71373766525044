import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { printClassName } from '@orgbrain/lib-data';

@Injectable({
  providedIn: 'root',
})
export class SharedUtilService {
  constructor() {
    printClassName(' ---> ', this);
  }

  getQueryParams(param): { [param: string]: string } {
    var vars = {};

    if (param[0] === '?') {
      param = param.substring(1);
    }

    const toks = param.split('&');

    toks.forEach((element) => {
      const toks2 = element.split('=');
      if (toks2.length === 2) {
        vars[toks2[0]] = toks2[1];
      } else {
        vars[toks2[0]] = true;
      }
    });

    return vars;
  }

  sensibleMessage(err: any): string {
    if (typeof err === 'string') {
      return err;
    } else if (err instanceof HttpErrorResponse && err.error.message) {
      return err.error.message;
    } else if (err.message) {
      return err.message;
    } else {
      return err.toString();
    }
  }

  validEmail(email: string): boolean {
    if (!email) return false;
    return (
      email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
    );
  }

  copyLink(value) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
