export const EDUCATIONS = {
  'EDUCATION ARCHAEOLOGY': {
    en: 'Archaeology',
    nb: 'Arkeologi',
  },
  'EDUCATION ARCHITECTURE': {
    en: 'Architecture',
    nb: 'Arkitektur',
  },
  'EDUCATION ARCHIVE AND DOCUMENTATION': {
    en: 'Archive and documentation',
    nb: 'Arkiv og dokumentasjon',
  },
  'EDUCATION AUDIOGRAPHER': {
    en: 'Audiographer',
    nb: 'Audiograf',
  },
  'EDUCATION AUDIOGRAPHER TRAINING': {
    en: 'Audiographer training',
    nb: 'Audiografutdanning',
  },
  'EDUCATION BAKER AND PASTRY CHEF': {
    en: 'Baker and pastry chef',
    nb: 'Baker og konditor',
  },
  'EDUCATION KINDERGARTEN, SCHOOL AND EDUCATION MANAGEMENT': {
    en: 'Kindergarten, school and education management',
    nb: 'Barnehage, skole -og utdanningsledelse',
  },
  'EDUCATION KINDERGARTEN TEACHER': {
    en: 'Kindergarten teacher',
    nb: 'Barnehagelærer',
  },
  'EDUCATION CHILD PROTECTION EDUCATOR': {
    en: 'Child protection educator',
    nb: 'Barnevernpedagog',
  },
  'EDUCATION OFFICER TRAINING': {
    en: 'Officer training',
    nb: 'Befalsutdanning',
  },
  'EDUCATION LIBRARIAN': {
    en: 'Librarian',
    nb: 'Bibliotekar',
  },
  'EDUCATION BIOENGINEER': {
    en: 'Bioengineer',
    nb: 'Bioingeniør',
  },
  'EDUCATION BIOLOGY': {
    en: 'Biology',
    nb: 'Biologi',
  },
  'EDUCATION AID AND DEVELOPMENT': {
    en: 'Aid and development',
    nb: 'Bistand og utvikling',
  },
  'EDUCATION FIRE SAFETY': {
    en: 'Fire safety',
    nb: 'Brannsikkerhet',
  },
  'EDUCATION CONSTRUCTION': {
    en: 'Construction',
    nb: 'Bygg og anlegg',
  },
  'EDUCATION CONSTRUCTION ENGINEER': {
    en: 'Construction engineer',
    nb: 'Byggingeniør',
  },
  'EDUCATION URBAN PLANNING': {
    en: 'Urban planning',
    nb: 'Byplanlegging',
  },
  'EDUCATION SUSTAINABILITY AND CIRCULAR ECONOMY': {
    en: 'Sustainability and circular economy',
    nb: 'Bærekraft og sirkulær økonomi',
  },
  'EDUCATION DANCE AND BALLET EDUCATION': {
    en: 'Dance and ballet education',
    nb: 'Danse- og ballettutdanning',
  },
  'EDUCATION COMPUTER ENGINEER': {
    en: 'Computer engineer',
    nb: 'Dataingeniør',
  },
  'EDUCATION DATA SECURITY': {
    en: 'Data security',
    nb: 'Datasikkerhet',
  },
  'EDUCATION DATA ANALYSIS': {
    en: 'Data analysis',
    nb: 'Dataanalyse',
  },
  'EDUCATION DEACONRY': {
    en: 'Deaconry',
    nb: 'Diakoni',
  },
  'EDUCATION ANIMAL CARE': {
    en: 'Animal care',
    nb: 'Dyrepleie',
  },
  'EDUCATION REAL ESTATE BROKERAGE': {
    en: 'Real estate brokerage',
    nb: 'Eiendomsmegling',
  },
  'EDUCATION ELECTRICAL ENGINEER': {
    en: 'Electrical engineer',
    nb: 'Elektroingeniør',
  },
  'EDUCATION ENERGY': {
    en: 'Energy',
    nb: 'Energi',
  },
  'EDUCATION OCCUPATIONAL THERAPY': {
    en: 'Occupational therapy',
    nb: 'Ergoterapi',
  },
  'EDUCATION NUTRITION': {
    en: 'Nutrition',
    nb: 'Ernæring',
  },
  'EDUCATION EX.PHIL AND EX.FAC': {
    en: 'Ex.phil and Ex.fac',
    nb: 'Ex.phil og Ex.fac',
  },
  'EDUCATION PHARMACY': {
    en: 'Pharmacy',
    nb: 'Farmasi',
  },
  'EDUCATION PRISON OFFICER': {
    en: 'Prison officer',
    nb: 'Fengselsbetjent',
  },
  'EDUCATION FILM AND TV PRODUCTION': {
    en: 'Film and TV production',
    nb: 'Film- og TV-produksjon',
  },
  'EDUCATION PHILOSOPHY AND ETHICS': {
    en: 'Philosophy and ethics',
    nb: 'Filosofi og etikk',
  },
  'EDUCATION FINANCE': {
    en: 'Finance',
    nb: 'Finans',
  },
  'EDUCATION FISHING AND AQUACULTURE': {
    en: 'Fishing and aquaculture',
    nb: 'Fiske og havbruk',
  },
  'EDUCATION PUBLIC HEALTH': {
    en: 'Public health',
    nb: 'Folkehelse',
  },
  'EDUCATION AUTHOR TRAINING': {
    en: 'Author training',
    nb: 'Forfatterutdanning',
  },
  'EDUCATION RENEWABLE ENERGY': {
    en: 'Renewable energy',
    nb: 'Fornybar energi',
  },
  'EDUCATION PHOTOGRAPH': {
    en: 'Photograph',
    nb: 'Fotografi',
  },
  'EDUCATION OUTDOOR LIFE': {
    en: 'Outdoor life',
    nb: 'Friluftsliv',
  },
  'EDUCATION HAIRDRESSER': {
    en: 'Hairdresser',
    nb: 'Frisør',
  },
  'EDUCATION PHYSICS': {
    en: 'Physics',
    nb: 'Fysikk',
  },
  'EDUCATION PHYSIOTHERAPY': {
    en: 'Physiotherapy',
    nb: 'Fysioterapi',
  },
  'EDUCATION GEOLOGY': {
    en: 'Geology',
    nb: 'Geologi',
  },
  'EDUCATION GRAPHIC DESIGN': {
    en: 'Graphic design',
    nb: 'Grafisk design',
  },
  'EDUCATION TRADE AND PURCHASING': {
    en: 'Trade and purchasing',
    nb: 'Handel og innkjøp',
  },
  'EDUCATION HEALTH PROFESSIONS': {
    en: 'Health professions',
    nb: 'Helsefag',
  },
  'EDUCATION HEALTH MANAGEMENT': {
    en: 'Health management',
    nb: 'Helseledelse',
  },
  'EDUCATION HISTORY': {
    en: 'History',
    nb: 'Historie',
  },
  'EDUCATION HOTEL AND RESTAURANT': {
    en: 'Hotel and restaurant',
    nb: 'Hotell og restaurant',
  },
  'EDUCATION HR AND PERSONNEL MANAGEMENT': {
    en: 'HR and personnel management',
    nb: 'HR og personalledelse',
  },
  'EDUCATION ANIMAL SCIENCE': {
    en: 'Animal science',
    nb: 'Husdyrfag',
  },
  'EDUCATION SPORTS': {
    en: 'Sports',
    nb: 'Idrett',
  },
  'EDUCATION INDUSTRIAL DESIGN': {
    en: 'Industrial design',
    nb: 'Industridesign',
  },
  'EDUCATION INDUSTRIAL ECONOMY AND TECHNOLOGY MANAGEMENT': {
    en: 'industrial economy and Technology Management',
    nb: 'Industriell økonomi og teknologiledelse',
  },
  'EDUCATION INFORMATION AND COMPUTER TECHNOLOGY': {
    en: 'Information and computer technology',
    nb: 'Informasjons- og datateknologi',
  },
  'EDUCATION ENGINEER': {
    en: 'Engineer',
    nb: 'Ingeniør',
  },
  'EDUCATION INNOVATION AND ENTREPRENEURSHIP': {
    en: 'Innovation and entrepreneurship',
    nb: 'Innovasjon og entreprenørskap',
  },
  'EDUCATION INTERACTION DESIGN': {
    en: 'Interaction design',
    nb: 'Interaksjonsdesign',
  },
  'EDUCATION INTERIOR AND DECORATOR EDUCATION': {
    en: 'Interior and decorator education',
    nb: 'Interiør- og dekoratørutdanning',
  },
  'EDUCATION INTERIOR DESIGN': {
    en: 'Interior design',
    nb: 'Interiørdesign',
  },
  'EDUCATION INTERNATIONAL BUSINESS': {
    en: 'International business',
    nb: 'Internasjonal virksomhet',
  },
  'EDUCATION AGRICULTURE': {
    en: 'Agriculture',
    nb: 'Landbruk',
  },
  'EDUCATION MIDWIFE': {
    en: 'Midwife',
    nb: 'Jordmor',
  },
  'EDUCATION JOURNALISM': {
    en: 'Journalism',
    nb: 'Journalistikk',
  },
  'EDUCATION LAW/JURISPRUDENCE': {
    en: 'Law/jurisprudence',
    nb: 'Juss/rettsvitenskap',
  },
  'EDUCATION CAREER GUIDANCE AND ADVICE': {
    en: 'Career guidance and advice',
    nb: 'Karriereveiledning og rådgivning',
  },
  'EDUCATION CHEMISTRY AND BIOTECHNOLOGY': {
    en: 'Chemistry and biotechnology',
    nb: 'Kjemi og bioteknologi',
  },
  'EDUCATION GENDER STUDIES': {
    en: 'Gender studies',
    nb: 'Kjønnsstudier',
  },
  'EDUCATION COOK': {
    en: 'Cook',
    nb: 'Kokk',
  },
  'EDUCATION COMMUNICATION AND PR': {
    en: 'Communication and PR',
    nb: 'Kommunikasjon og PR',
  },
  'EDUCATION CRIMINOLOGY': {
    en: 'Criminology',
    nb: 'Kriminologi',
  },
  'EDUCATION CULTURAL EDUCATION': {
    en: 'Cultural education',
    nb: 'Kulturutdanning',
  },
  'EDUCATION ARTS AND CRAFTS': {
    en: 'Arts and crafts',
    nb: 'Kunst og kunsthåndverk',
  },
  'EDUCATION ART HISTORY': {
    en: 'Art history',
    nb: 'Kunsthistorie',
  },
  'EDUCATION ARTIFICIAL INTELLIGENCE': {
    en: 'Artificial intelligence',
    nb: 'Kunstig inteligens',
  },
  'EDUCATION SURVEYING/GEOMETRICS': {
    en: 'Surveying/geometrics',
    nb: 'Landmåling/geomatikk',
  },
  'EDUCATION MANAGEMENT': {
    en: 'Management',
    nb: 'Ledelse',
  },
  'EDUCATION LECTURER': {
    en: 'Lecturer',
    nb: 'Lektor',
  },
  'EDUCATION LITERATURE': {
    en: 'Literature',
    nb: 'Litteratur',
  },
  'EDUCATION LOGISTICS': {
    en: 'Logistics',
    nb: 'Logistikk',
  },
  'EDUCATION AVIATION': {
    en: 'Aviation',
    nb: 'Luftfart',
  },
  'EDUCATION SOUND AND LIGHTING DESIGN': {
    en: 'Sound and lighting design',
    nb: 'Lyd- og lysdesign',
  },
  'EDUCATION TEACHER STUDIES': {
    en: 'Teacher studies',
    nb: 'Lærerstudier',
  },
  'EDUCATION MARINE AND FRESHWATER BIOLOGY': {
    en: 'Marine and freshwater biology',
    nb: 'Marin- og ferskvannsbiologi',
  },
  'EDUCATION MARKETING': {
    en: 'Marketing',
    nb: 'Markedsføring',
  },
  'EDUCATION MACHINE, PROCESS AND PRODUCTION': {
    en: 'Machine, process and production',
    nb: 'Maskin, prosess og produksjon',
  },
  'EDUCATION MATHEMATICS AND SCIENCE SUBJECTS': {
    en: 'Mathematics and science subjects',
    nb: 'Matematisk-naturvitenskapelige fag',
  },
  'EDUCATION MATERIALS TECHNOLOGY': {
    en: 'Materials technology',
    nb: 'Materialteknologi',
  },
  'EDUCATION FOOD SCIENCE': {
    en: 'Food science',
    nb: 'Matvitenskap',
  },
  'EDUCATION MEDIA PRODUCTION': {
    en: 'Media production',
    nb: 'Medieproduksjon',
  },
  'EDUCATION MEDIA SCIENCE': {
    en: 'Media science',
    nb: 'Medievitenskap',
  },
  'EDUCATION MEDICINE AND HEALTH SCIENCES': {
    en: 'Medicine and health sciences',
    nb: 'Medisin og helsefag',
  },
  'EDUCATION MILITARY SUBJECT': {
    en: 'Military subject',
    nb: 'Militær fag',
  },
  'EDUCATION ENVIRONMENT AND POLLUTION': {
    en: 'Environment and pollution',
    nb: 'Miljø og forurensning',
  },
  'EDUCATION ENVIRONMENT AND CLIMATE': {
    en: 'Environment and climate',
    nb: 'Miljø og klima',
  },
  'EDUCATION FASHION DESIGN': {
    en: 'Fashion design',
    nb: 'Motedesign',
  },
  'EDUCATION MUSIC': {
    en: 'Music',
    nb: 'Musikk',
  },
  'EDUCATION MUSIC THERAPY': {
    en: 'Music therapy',
    nb: 'Musikkterapi',
  },
  'EDUCATION NATURE MANAGEMENT': {
    en: 'Nature management',
    nb: 'Naturforvaltning',
  },
  'EDUCATION NETWORKS AND DATA COMMUNICATIONS': {
    en: 'Networks and data communications',
    nb: 'Nettverk og datakommunikasjon',
  },
  'EDUCATION ODONTOLOGY': {
    en: 'Odontology',
    nb: 'Odontologi',
  },
  'EDUCATION OPTICS': {
    en: 'Optics',
    nb: 'Optikk',
  },
  'EDUCATION ORGANIZATION AND MANAGEMENT': {
    en: 'Organization and management',
    nb: 'Organisasjon og ledelse',
  },
  'EDUCATION TRANSLATION': {
    en: 'Translation',
    nb: 'Oversetning',
  },
  'EDUCATION PARAMEDICINE': {
    en: 'Paramedicine',
    nb: 'Paramedisin',
  },
  'EDUCATION PEDAGOGY': {
    en: 'Pedagogy',
    nb: 'Pedagogikk',
  },
  'EDUCATION PETROLEUM TECHNOLOGY': {
    en: 'Petroleum technology',
    nb: 'Petroleumsteknologi',
  },
  'EDUCATION PLANT SCIENCE': {
    en: 'Plant Science',
    nb: 'Plantevitenskap',
  },
  'EDUCATION POLICE TRAINING': {
    en: 'Police training',
    nb: 'Politiutdanning',
  },
  'EDUCATION PRACTICAL PEDAGOGICAL EDUCATION': {
    en: 'Practical pedagogical education',
    nb: 'Praktisk-pedagogisk utdanning',
  },
  'EDUCATION PRODUCT AND INDUSTRIAL DESIGN': {
    en: 'Product and industrial design',
    nb: 'Produkt- og industridesign',
  },
  'EDUCATION PROJECT MANAGEMENT': {
    en: 'Project management',
    nb: 'Prosjektledelse',
  },
  'EDUCATION PSYCHOLOGY': {
    en: 'Psychology',
    nb: 'Psykologi',
  },
  'EDUCATION ACCOUNTING': {
    en: 'Accounting',
    nb: 'Regnskap',
  },
  'EDUCATION TRAVEL LIFE': {
    en: 'Travel life',
    nb: 'Reiseliv',
  },
  'EDUCATION RELIGION': {
    en: 'Religion',
    nb: 'Religion',
  },
  'EDUCATION REVISION': {
    en: 'Revision',
    nb: 'Revisjon',
  },
  'EDUCATION RISK MANAGEMENT, COMPLIANCE AND INTERNAL AUDIT': {
    en: 'Risk management, compliance and internal audit',
    nb: 'Risikostyring, compliance og internrevisjon',
  },
  'EDUCATION SPACE TRAVEL': {
    en: 'Space travel',
    nb: 'Romfart',
  },
  'EDUCATION SALES AND SALES MANAGEMENT': {
    en: 'Sales and sales management',
    nb: 'Salg og salgsledelse',
  },
  'EDUCATION SOCIAL GEOGRAPHY': {
    en: 'Social geography',
    nb: 'Samfunnsgeografi',
  },
  'EDUCATION COMMUNITY PLANNING': {
    en: 'Community planning',
    nb: 'Samfunnsplanlegging',
  },
  'EDUCATION COMMUNITY SAFETY (HMS)': {
    en: 'Community safety (HMS)',
    nb: 'Samfunnssikkerhet (HMS)',
  },
  'EDUCATION SOCIAL SCIENCE': {
    en: 'Social science',
    nb: 'Samfunnsvitenskap',
  },
  'EDUCATION SOCIAL ECONOMICS': {
    en: 'Social economics',
    nb: 'Samfunnsøkonomi',
  },
  'EDUCATION SHIPPING': {
    en: 'Shipping',
    nb: 'Shipping',
  },
  'EDUCATION CIVIL ENGINEER': {
    en: 'Civil engineer',
    nb: 'Sivilingeniør',
  },
  'EDUCATION CIVIL ECONOMIST': {
    en: 'Civil economist',
    nb: 'Siviløkonom',
  },
  'EDUCATION BEAUTY AND WELL-BEING': {
    en: 'Beauty and well-being',
    nb: 'Skjønnhet og velvære',
  },
  'EDUCATION FORESTRY': {
    en: 'Forestry',
    nb: 'Skogbruk',
  },
  'EDUCATION SOCIAL ANTHROPOLOGY': {
    en: 'Social anthropology',
    nb: 'Sosialantropologi',
  },
  'EDUCATION SOCIOLOGY': {
    en: 'Sociology',
    nb: 'Sosiologi',
  },
  'EDUCATION SOCIOLOGIST': {
    en: 'Sociologist',
    nb: 'Sosionom',
  },
  'EDUCATION SPECIAL PEDAGOGY': {
    en: 'Special pedagogy',
    nb: 'Spesialpedagogikk',
  },
  'EDUCATION SPORTS MANAGEMENT': {
    en: 'Sports management',
    nb: 'Sportsledelse',
  },
  'EDUCATION LANGUAGE': {
    en: 'Language',
    nb: 'Språk',
  },
  'EDUCATION STATISTICS': {
    en: 'Statistics',
    nb: 'Statistikk',
  },
  'EDUCATION POLITICAL SCIENCE': {
    en: 'Political science',
    nb: 'Statsvitenskap',
  },
  'EDUCATION STRATEGY AND MANAGEMENT': {
    en: 'Strategy and management',
    nb: 'Strategi og ledelse',
  },
  'EDUCATION BOARD EDUCATION': {
    en: 'Board education',
    nb: 'Styreutdanning',
  },
  'EDUCATION NURSE': {
    en: 'Nurse',
    nb: 'Sykepleier',
  },
  'EDUCATION DENTAL CARE AND DENTAL TECHNOLOGY': {
    en: 'Dental care and dental technology',
    nb: 'Tannpleie og tannteknikk',
  },
  'EDUCATION THEATER AND DRAMA': {
    en: 'Theater and drama',
    nb: 'Teater og drama',
  },
  'EDUCATION SIGN LANGUAGE': {
    en: 'Sign language',
    nb: 'Tegnspråk',
  },
  'EDUCATION TECHNOLOGY': {
    en: 'Technology',
    nb: 'Teknologi',
  },
  'EDUCATION TEXTILE DESIGN': {
    en: 'Textile design',
    nb: 'Tekstildesign',
  },
  'EDUCATION THEOLOGY': {
    en: 'Theology',
    nb: 'Teologi',
  },
  'EDUCATION THERAPEUTIC STUDIES': {
    en: 'Therapeutic studies',
    nb: 'Terapeutiske studier',
  },
  'EDUCATION TOLLS': {
    en: 'Tolls',
    nb: 'Toller',
  },
  'EDUCATION TRAFFIC TEACHER': {
    en: 'Traffic teacher',
    nb: 'Trafikklærer',
  },
  'EDUCATION CAREGIVER': {
    en: 'Caregiver',
    nb: 'Vernepleier',
  },
  'EDUCATION VETERINARIAN': {
    en: 'Veterinarian',
    nb: 'Veterinær',
  },
  'EDUCATION BUSINESS ADMINISTRATION': {
    en: 'Business Administration',
    nb: 'Økonomi og administrasjon',
  },
};

export const textMappings = { ...EDUCATIONS };

// Helper function to create translations
// (function () {
//   const textMappings = {};
//   const arrEng = ['Archaeology'];
//   const arrNb = ['Arkeologi'];
//   for (let i = 0; i < arrEng.length; i++) {
//     const enKey = arrEng[i];
//     const nbKey = arrNb[i];

//     const structureKey = 'EDUCATION ' + enKey.toUpperCase();
//     textMappings[structureKey] = {
//       en: enKey,
//       nb: nbKey,
//     };
//   }
//   console.log(JSON.stringify(textMappings, null, 2));
// })();
