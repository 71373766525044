export enum ModuleEnum {
  home = 'home',
  meetings = 'meetings',
  shareholders = 'shareholders',
  news = 'news',
  files = 'files',
  tasks = 'tasks',
  chat = 'chat',
  boardschool = 'boardschool',
  // boardmatch = 'boardmatch',
  surveys = 'surveys',
  academy = 'academy',
  boardmatch_org = 'boardmatch-org',
  organization = 'organization',
  expertHelp = 'expertHelp',
  boardmatch_candidate = 'boardmatch-candidate',
  investments = 'investments',
  templates = 'templates',
  configure = 'configure',
  admin = 'admin',
  external = 'external',
}
