<svg
  class="logo"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 1086.06 428.37"
  [ngStyle]="{ 'transform-origin': 'top left', transform: transform }"
>
  >
  <defs>
    <style>
      .cls-1 {
        fill: #f57c00;
      }
      .cls-2 {
        fill: #546e7a;
      }
    </style>
  </defs>
  <title>Asset 1</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <rect
        class="cls-1"
        x="963.71"
        y="168.27"
        width="76.95"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(2004.36 356.51) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="931.09"
        y="134.62"
        width="143.09"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(2005.28 289.21) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="868.76"
        y="134.62"
        width="42.13"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1779.65 289.21) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="820.28"
        y="134.62"
        width="28.27"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1668.83 289.2) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="966.62"
        y="100.96"
        width="119.44"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(2052.67 221.9) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="837.64"
        y="100.96"
        width="108.7"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1783.97 221.9) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="789.08"
        y="100.96"
        width="28.27"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1606.44 221.9) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="915.07"
        y="67.31"
        width="163.76"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1993.89 154.59) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="846.39"
        y="67.31"
        width="48.4"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1741.18 154.59) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="797.83"
        y="67.31"
        width="28.27"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1623.94 154.59) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="950.59"
        y="33.65"
        width="105.71"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(2006.89 87.28) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="866.02"
        y="33.65"
        width="64.29"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1796.33 87.28) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="817.47"
        y="33.65"
        width="28.27"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1663.21 87.28) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="906.75"
        width="104.5"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1918.01 19.97) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="857.2"
        width="29.28"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1743.67 19.97) rotate(-180)"
      />
      <rect
        class="cls-1"
        x="952.39"
        y="201.92"
        width="28.27"
        height="19.97"
        rx="7.62"
        ry="7.62"
        transform="translate(1933.05 423.82) rotate(-180)"
      />
      <path
        class="cls-2"
        d="M163.41,293.87q0,22.38-6.54,39.47t-17.72,28.53a73.1,73.1,0,0,1-26,17.22,85.75,85.75,0,0,1-31.42,5.78,85.73,85.73,0,0,1-31.42-5.78,73,73,0,0,1-26-17.22Q13.07,350.43,6.54,333.34T0,293.87q0-22.37,6.54-39.34a82.32,82.32,0,0,1,17.72-28.41,73.2,73.2,0,0,1,26-17.22,85.79,85.79,0,0,1,31.42-5.78,85.81,85.81,0,0,1,31.42,5.78,73.25,73.25,0,0,1,26,17.22,82.41,82.41,0,0,1,17.72,28.41Q163.41,271.49,163.41,293.87Zm-18.1,0a102,102,0,0,0-4.53-31.55A69.49,69.49,0,0,0,128,238.56a54.85,54.85,0,0,0-20-15,64.55,64.55,0,0,0-26.27-5.15,64.54,64.54,0,0,0-26.27,5.15,54.85,54.85,0,0,0-20,15,69.49,69.49,0,0,0-12.82,23.76,112.28,112.28,0,0,0,0,63.1,69.46,69.46,0,0,0,12.82,23.76,56.11,56.11,0,0,0,20,15.09,63.14,63.14,0,0,0,26.27,5.28A63.15,63.15,0,0,0,108,364.26a56.11,56.11,0,0,0,20-15.09,69.46,69.46,0,0,0,12.82-23.76A102,102,0,0,0,145.31,293.87Z"
      />
      <path
        class="cls-2"
        d="M235.94,248.61a88.13,88.13,0,0,1,15.21,1.26,46.85,46.85,0,0,1,9.93,2.52l-3.27,14.08a36.51,36.51,0,0,0-8.17-2.14,93.32,93.32,0,0,0-16.22-1.13,76.86,76.86,0,0,0-17,1.51,47,47,0,0,0-8.42,2.52V376a5.13,5.13,0,0,1-5.13,5.13h-6.09a5.13,5.13,0,0,1-5.13-5.13V257.16A120.78,120.78,0,0,1,209,251.5Q220.09,248.62,235.94,248.61Z"
      />
      <path
        class="cls-2"
        d="M358.24,366a28.45,28.45,0,0,1-4.53,2.89,71.2,71.2,0,0,1-7.42,3.39,57.6,57.6,0,0,1-10.05,2.89,65.06,65.06,0,0,1-12.7,1.13,68.6,68.6,0,0,1-21.87-3.52,48.81,48.81,0,0,1-18.48-11.06,54.19,54.19,0,0,1-12.7-19.48q-4.78-11.94-4.78-29A74.81,74.81,0,0,1,270,287.58a60.53,60.53,0,0,1,12.19-20.49,55.31,55.31,0,0,1,19.36-13.58,64.85,64.85,0,0,1,25.77-4.9q18.1,0,29,2.39,8.34,1.82,14.42,3.57a5,5,0,0,1,3.56,4.81V374.31q0,29.16-15.08,41.61t-45.5,12.44A125.39,125.39,0,0,1,291,426.48q-7.23-1.34-13-3a5.07,5.07,0,0,1-3.45-6l2.25-10.23A74.3,74.3,0,0,0,293.09,412a111.42,111.42,0,0,0,20.3,2q23.79.17,34.42-8.92,10.43-8.92,10.43-30.54ZM358,267a96.72,96.72,0,0,0-11.06-2.39,125.6,125.6,0,0,0-19.36-1.13,45.54,45.54,0,0,0-18.73,3.65,39.61,39.61,0,0,0-14,10.31,45.58,45.58,0,0,0-8.8,15.84,64.52,64.52,0,0,0-3,20.24q0,12.82,3.52,22a43.1,43.1,0,0,0,9.18,15,34.18,34.18,0,0,0,12.95,8.42,43.23,43.23,0,0,0,14.83,2.64,60,60,0,0,0,20.24-3.27Q353,355,358,350.68Z"
      />
      <path
        class="cls-2"
        d="M423.15,258.92q4.27-3.26,13.7-6.91t22.25-3.64a59.27,59.27,0,0,1,25,5,52,52,0,0,1,18.48,14.08A62.2,62.2,0,0,1,514,289a91,91,0,0,1,3.9,27.28,81.47,81.47,0,0,1-4.65,28.41A61.13,61.13,0,0,1,500.2,366a56.23,56.23,0,0,1-20.11,13.45,69.87,69.87,0,0,1-26,4.65q-17.35,0-28.66-2.26a146.37,146.37,0,0,1-18.6-4.78V189l10.34-1.91a5.08,5.08,0,0,1,6,5Zm0,106.84a72.14,72.14,0,0,0,11.19,2.39,134,134,0,0,0,19.48,1.13q21.12,0,33.94-13.7t12.82-39.34a89.82,89.82,0,0,0-2.26-20.49A48,48,0,0,0,491,278.91a36.56,36.56,0,0,0-13.2-11.44q-8.17-4.27-20.24-4.27a52.66,52.66,0,0,0-11.06,1.13,64.13,64.13,0,0,0-9.8,2.89,54.08,54.08,0,0,0-7.92,3.77,55.3,55.3,0,0,0-5.66,3.77Z"
      />
      <path
        class="cls-2"
        d="M587.89,248.61a88.13,88.13,0,0,1,15.21,1.26,46.85,46.85,0,0,1,9.93,2.52l-3.27,14.08a36.51,36.51,0,0,0-8.17-2.14,93.32,93.32,0,0,0-16.22-1.13,76.86,76.86,0,0,0-17,1.51,47,47,0,0,0-8.42,2.52V376a5.13,5.13,0,0,1-5.13,5.13h-6.09a5.13,5.13,0,0,1-5.13-5.13V257.16A120.79,120.79,0,0,1,561,251.5Q572,248.62,587.89,248.61Z"
      />
      <path
        class="cls-1"
        d="M667.31,248.11q12.82,0,21.75,3.64a37.35,37.35,0,0,1,14.46,10.05,39.65,39.65,0,0,1,8,15.21,69.46,69.46,0,0,1,2.51,19.11v82a74.09,74.09,0,0,1-7.29,1.63q-4.78.88-11.06,1.89t-14,1.63q-7.67.62-15.71.63a75.09,75.09,0,0,1-19-2.26A41.92,41.92,0,0,1,632,374.44a33.74,33.74,0,0,1-10.06-12.57q-3.65-7.66-3.64-18.73,0-10.56,4-18.1a34.19,34.19,0,0,1,11.31-12.44A50.7,50.7,0,0,1,651,305.43a99.43,99.43,0,0,1,21.87-2.26q3.52,0,7.42.38t7.54,1q3.64.63,6.28,1.26a19.28,19.28,0,0,1,3.64,1.13v-8a85.29,85.29,0,0,0-1-13.2,28.93,28.93,0,0,0-4.4-11.56,24.46,24.46,0,0,0-9.55-8.3q-6.16-3.14-16.21-3.14-14.33,0-21.37,2T634.87,268l-2.26-14.33q4.27-2,13.2-3.77A113.18,113.18,0,0,1,667.31,248.11Zm1.51,121.17q9.55,0,16.72-.63A113.24,113.24,0,0,0,697.72,367V321.27a50.39,50.39,0,0,0-8.8-2.64,77.89,77.89,0,0,0-16.59-1.38,94.36,94.36,0,0,0-12.57.88,36.38,36.38,0,0,0-12.07,3.77,25,25,0,0,0-9.05,7.92q-3.52,5-3.52,13.32a28.51,28.51,0,0,0,2.39,12.32,19.86,19.86,0,0,0,6.79,8,30.06,30.06,0,0,0,10.56,4.4A63.77,63.77,0,0,0,668.81,369.29Z"
      />
      <path
        class="cls-1"
        d="M765.47,211.91a12.15,12.15,0,0,1-3.52,9.18,12.46,12.46,0,0,1-17.1,0,13.72,13.72,0,0,1,0-18.35,12.46,12.46,0,0,1,17.1,0A12.15,12.15,0,0,1,765.47,211.91Zm-8.9,169.19h-6.09a5.13,5.13,0,0,1-5.13-5.13V256.26a5.13,5.13,0,0,1,5.13-5.13h6.09a5.13,5.13,0,0,1,5.13,5.13V376A5.13,5.13,0,0,1,756.58,381.1Z"
      />
      <path
        class="cls-2"
        d="M794.26,255.65q7.29-2,19.61-4.52T844,248.61q14.83,0,24.76,4.27a38.09,38.09,0,0,1,15.84,12.19,49.8,49.8,0,0,1,8.42,19,110.33,110.33,0,0,1,2.51,24.39V376a5.13,5.13,0,0,1-5.13,5.13h-6.09a5.13,5.13,0,0,1-5.13-5.13V313.73a123.08,123.08,0,0,0-1.88-23.38q-1.89-9.55-6.28-15.59a24.45,24.45,0,0,0-11.56-8.67q-7.17-2.64-18-2.64a137.68,137.68,0,0,0-20,1.26Q813.11,266,810.6,267V376a5.13,5.13,0,0,1-5.13,5.13h-6.09a5.13,5.13,0,0,1-5.13-5.13Z"
      />
    </g>
  </g>
</svg>
