import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgbrainLayoutService {
  static toolBarHeight = 82; // see also shared/_dimensions.scss
  static dialogDefaultWidth = 570;
  static dialogNarrowWidth = 470;
  static minDeviceWidth = 360;

  changes$: BehaviorSubject<OrgbrainLayoutService>;
  public mobile = false;
  public showVideo: boolean;
  video$ = new BehaviorSubject<boolean>(false);
  menuClose$ = new BehaviorSubject<boolean>(false);
  videoDiv: any;

  constructor(public breakpointObserver: BreakpointObserver) {
    this.changes$ = new BehaviorSubject<OrgbrainLayoutService>(this);
    this.breakpointObserver.observe(['(min-width: 570px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mobile = false;
      } else {
        this.mobile = true;
      }
      this.changes$.next(this);
    });
  }

  showVideoWindow(yes: boolean) {
    this.showVideo = yes;
    this.video$.next(yes);
  }

  hideMenuIfMobile() {
    if (this.mobile) {
      this.menuClose$.next(true);
    }
  }
}
