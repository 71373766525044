/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ComponentRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { Type } from '@angular/core';

@Component({
  selector: 'orgbrain-component-container',
  templateUrl: './component-container.component.html',
})
export class ComponentContainerComponent implements AfterViewInit {
  @Input() componentType: Type<any>;
  // @ViewChild("viewContainerRef", { read: ViewContainerRef, static: true }) vcr!: ViewContainerRef;

  ref!: ComponentRef<Type<any>>

  @ViewChild('templatePortalContent') templatePortalContent: TemplateRef<unknown>;
  templatePortal: ComponentPortal<unknown>;

  constructor(private _viewContainerRef: ViewContainerRef,) { }




  ngAfterViewInit() {
    setTimeout(() => {
      // this.templatePortal = new TemplatePortal(this.templatePortalContent, this._viewContainerRef);
      // const ref = this._viewContainerRef.createComponent(this.componentType);


      this.templatePortal = new ComponentPortal(this.componentType);


    });
  }

}
