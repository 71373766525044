import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orgbrain-simple-spinner',
  template: `
    <div class="submitting-changes-spinner-container">
      <div class="submitting-changes-spinner" [ngClass]="{ small: size === 'small' }"></div>
    </div>
  `,
  styleUrls: ['./simple-spinner.component.scss'],
})
export class SimpleSpinnerComponent implements OnInit {
  @Input() size: 'small' | 'normal' = 'normal';

  constructor() {}

  ngOnInit() {}
}
