/*
 *  Copyright 2010-2016 FinTech Neo AS ( fintechneo.com )- All rights reserved
 */

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: 'confirmdialog.component.html',
  styleUrls: ['dialog.component.scss'],
})
export class ConfirmDialogComponent {
  subtitle: string;
  yesOptionTitle: string;
  noOptionTitle: string;
  alternativeOptionTitle: string;
  title: string;
  question: string | string[];
  reverseLogic: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reverseLogic = data && data.reverseLogic;
  }

  isQuestionAnArray() {
    return Array.isArray(this.question);
  }
}
