<!-- <a *ngIf="orgbrainUrl" [href]="orgbrainUrl" target="_blank" rel="noopener" mat-icon-button>
  <mat-icon [inline]="true">help_outline</mat-icon>
</a> -->

<span
  *ngIf="orgbrainHelp"
  target="_blank"
  rel="noopener"
  mat-icon-button
  style="vertical-align: top; margin-left: 5px"
  (click)="showingHelp = !showingHelp"
>
  <mat-icon class="canpress" [inline]="true" style="opacity: 0.5">help_outline</mat-icon>
</span>

<div
  class="popup-help body-text-2 canpress"
  (click)="showingHelp = false"
  [@openClose]="showingHelp ? 'open' : 'closed'"
>
  <!-- <mat-icon class="icon-float" [inline]="true">close</mat-icon> -->
  <div>{{ orgbrainHelp }}</div>
</div>
