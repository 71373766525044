import { CompanyAcronymsEnum, Role, RoleByTime } from '@orgbrain/roles';
import { MongoId } from './chatChannel';
import { IdentityType } from './session';
import { IUser } from './user';

export enum ImplicitVotingSettings {
  IMPLICIT_FOR = 'IMPLICIT_FOR',
  NO_IMPLICIT_FOR = 'NO_IMPLICIT_FOR',
}

export enum AgendaItemStylesSettings {
  AGENDA_STYLE_NUMBER = 'AGENDA_STYLE_NUMBER',
  AGENDA_STYLE_ITEM_NUMBER = 'AGENDA_STYLE_ITEM_NUMBER',
  AGENDA_STYLE_CONSECUTIVE_NUMBER = 'AGENDA_STYLE_CONSECUTIVE_NUMBER',
  AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER = 'AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER',
  AGENDA_STYLE_NUMBER_MEETINGN_YEAR = 'AGENDA_STYLE_NUMBER_MEETINGN_YEAR',
  AGENDA_STYLE_ITEM_NUMBER_MEETINGN_YEAR = 'AGENDA_STYLE_ITEM_NUMBER_MEETINGN_YEAR',
  AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER_MEETINGN_YEAR = 'AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER_MEETINGN_YEAR',
  AGENDA_STYLE_ITEM_NO_NUMBER_AT_ALL = 'AGENDA_STYLE_ITEM_NO_NUMBER_AT_ALL',
}

export enum OrgLanguage {
  EN = 'EN',
  NO = 'NO',
  SE = 'SE',
}

export enum OrgJurisdiction {
  NO = 'NO',
  SE = 'SE',
  DK = 'DK',
}

export type IUserRoles = {
  _id: MongoId;
  organization: MongoId;
  user: MongoId | IUser;
  roles: Role[];
  rolesByTime: RoleByTime[];
  manualId: MongoId;
  invited: boolean;
};

export interface IOrganization {
  _id: any;
  orgId: string;
  name: string;
  clientId: string;

  createdById: MongoId;

  parentOrgNumber: string;
  organizationGroupParentId: MongoId;
  parentOrganization: MongoId;
  childOrgNumbers: string[];

  supportPermission: boolean;
  openaiPermission: boolean;
  orderId: MongoId;
  website: string;
  type?: CompanyAcronymsEnum;
  phone: string;
  city: string;
  address: string;
  postnr: string;
  established: string;
  email: string;
  invoiceEmail: string;
  roleMapTweaks: {};
  isPrivateOrg: boolean;
  extraSignaturesRequired: Role[];
  implicitVoting: ImplicitVotingSettings;

  agendaItemStyle: AgendaItemStylesSettings;
  agendaItemStyleGA: AgendaItemStylesSettings;
  agendaItemStyle3: AgendaItemStylesSettings;
  agendaItemStyle4: AgendaItemStylesSettings;
  deleted: boolean;
  language: OrgLanguage;
  jurisdiction: OrgJurisdiction;
  simplePDFFlag: boolean;
  shareClassDistributionFlag: boolean;
  showWhoHasVoted: boolean;
  showEngagementMeter: boolean;
  showAgendaItemResponsible: boolean;
  agendaItemUseStartTimeInsteadOfDuration: boolean;
  agendaItemShowTimeInMinutes?: boolean;
  showAgendaItemLogs: boolean;
  showAgendaSpeakingList: boolean;
  showAgendaComments: boolean;
  logo: any;
  activeCampaignId: string;
  demoMeetingsCreated: boolean;
  suspendedAt: Date;
  defaultFilePermissionsMap: {};
  requiredId: IdentityType[];
  roleTranslateTexts: {};
  authPolicy: {};
  earlyAccess: {};
  sportsOrgId: string;

  // not in schema butg added for bookkeeping
  isSupport?: boolean;
  createdAt: Date;
}

/*
 const orgs = await OrganizationModel.find(}).populate({
        path: 'members',
        populate: 'user',
      })
*/
export interface IOrganizationWithRoles extends IOrganization {
  members: IUserRoles[];
}

export type OrgAndClientLogoType = {
  url: string;
  urlForEmail: string;
  folderPath: string;
  extension: string;
  name: string;
  fileSize: number;
};
