export type SecurityStatusReportType =
  | 'PASS'
  | 'FAIL'
  | 'NUDGE'
  | 'ERROR'
  | 'SET'
  | 'HOME'
  | 'CLEAR'
  | 'BAILOUT'
  | 'BACK'
  | 'ABORT';
export type SecurityUserAbortButton = 'HOME' | 'BACK' | 'ABORT'; // HOME goes to select.    Back attempts to go back to previous route. Abort needs to be hndle by the caller.

export enum ReasonKeyType {
  SETUP_CREDENTIAL_FOR_ORG = 'SETUP_CREDENTIAL_FOR_ORG',
  SESSION_MFA_TO_SET_AN_ID_FOR_ORG = 'SESSION_MFA_TO_SET_AN_ID_FOR_ORG',
  NO_PERMISSION_SYSTEM_ERROR = 'NO_PERMISSION_SYSTEM_ERROR',
  SESSION_MFA_FOR_USER_SETTINGS = 'SESSION_MFA_FOR_USER_SETTINGS',
  FIXME = 'FIXME',
  NOT_SURE_ABOUT_THIS = 'NOT_SURE_ABOUT_THIS',
  SESSION_MFA_REQUIRED_FOR_ORG = 'SESSION_MFA_REQUIRED_FOR_ORG',
  SETUP_MFA_CREDENTIAL_FOR_ORG = 'SETUP_MFA_CREDENTIAL_FOR_ORG',
  ASSERT_MISSING_SESSION_CREDENTIAL_FOR_SETUP = 'ASSERT_MISSING_SESSION_CREDENTIAL_FOR_SETUP',
}
