import { ProgressDialogComponent } from './progressdialog.component';
import { ConfirmDialogComponent } from './confirmdialog.component';
import { InfoDialogComponent } from './infodialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrgBrainDialogService } from './ogbrainDialog.service';
import { BlockingDialogComponent } from './blockingdialog.component';
import { ErrorDialogComponent } from './errordialog/errordialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MultiChoiceDialogComponent } from './multichoicedialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WorkingDialogComponent } from './workingdialog.component';

import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { DialogTitleComponent } from '../style/dialog-title/dialog-title.component';
import { BlurOnInitDirective } from '../directives/blur.directive';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InfoBoxOpenButtonComponent } from './info-box/info-box-open-button.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    BlurOnInitDirective,
    MatTabsModule,
  ],
  declarations: [
    ProgressDialogComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    BlockingDialogComponent,
    ErrorDialogComponent,
    MultiChoiceDialogComponent,
    WorkingDialogComponent,
    LoadingSpinnerComponent,
    DialogTitleComponent,
    InfoBoxComponent,
    InfoBoxOpenButtonComponent,
  ],
  exports: [
    LoadingSpinnerComponent,
    DialogTitleComponent,
    InfoBoxComponent,
    InfoBoxOpenButtonComponent,
  ],

  providers: [OrgBrainDialogService],
})
export class OrgBrainDialogsModule {}
export { WorkingDialogComponent } from './workingdialog.component';
export { MultiChoiceDialogComponent } from './multichoicedialog.component';
