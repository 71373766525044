<div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <orgbrain-loading-spinner></orgbrain-loading-spinner>
</div>

<mat-paginator
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSizeReal"
  [pageSizeOptions]="[5, 10, 25, 50, 100, 1000]"
  (page)="onPageEvent($event)"
  class="no-underline"
  [class.hide]="hide"
>
</mat-paginator>
