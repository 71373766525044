export const countriesNames = {
  AFGHANISTAN: 'AF',
  ALBANIA: 'AL',
  ALGERIE: 'DZ',
  'AM. SAMOA': 'AS',
  ANDORRA: 'AD',
  ANGOLA: 'AO',
  ANGUILLA: 'AI',
  ANTARKTIS: 'AQ',
  'ANTIGUA OG BARBUDA': 'AG',
  ARGENTINA: 'AR',
  ARMENIA: 'AM',
  ARUBA: 'AW',
  ASERBAJDSJAN: 'AZ',
  AUSTRALIA: 'AU',
  BAHAMAS: 'BS',
  BAHRAIN: 'BH',
  BANGLADESH: 'BD',
  BARBADOS: 'BB',
  BELARUS: 'BY',
  BELGIA: 'BE',
  BELIZE: 'BZ',
  BENIN: 'BJ',
  BERMUDA: 'BM',
  BHUTAN: 'BT',
  BOLIVIA: 'BO',
  'BONAIRE, ST EUSTATIUS OG SABA': 'BQ',
  'BOSNIA-HERCEGOVINA': 'BA',
  BOTSWANA: 'BW',
  BOUVETØYA: 'BV',
  BRASIL: 'BR',
  'BRITISK-INDISKE HAV': 'IO',
  BRUNEI: 'BN',
  BULGARIA: 'BG',
  'BURKINA FASO': 'BF',
  BURUNDI: 'BI',
  CANADA: 'CA',
  CAYMANØYENE: 'KY',
  CHILE: 'CL',
  CHRISTMASØYA: 'CX',
  COLOMBIA: 'CO',
  COOKØYENE: 'CK',
  'COSTA RICA': 'CR',
  CUBA: 'CU',
  CURACAO: 'CW',
  DANMARK: 'DK',
  'DE ARABISKE EMIRATER': 'AE',
  'DE FRANSKE TERRITORIENE I SØR': 'TF',
  'DE NEDERLANDSKE ANTILLENE': 'AN',
  'DEN DOMINIKANSKE REP': 'DO',
  'DET PALESTINSKE OMRÅDET': 'PS',
  DIVERSE: 'ZZ',
  DJIBOUTI: 'DJ',
  DOMINICA: 'DM',
  ECUADOR: 'EC',
  EGYPT: 'EG',
  'EKVATORIAL-GUINEA': 'GQ',
  'EL SALVADOR': 'SV',
  ELFENBEINSKYSTEN: 'CI',
  ERITREA: 'ER',
  ESTLAND: 'EE',
  ETIOPIA: 'ET',
  FALKLANDSØYENE: 'FK',
  FIJI: 'FJ',
  FILIPPINENE: 'PH',
  FINLAND: 'FI',
  FRANKRIKE: 'FR',
  'FRANSK GUYANA': 'GF',
  'FRANSK POLYNESIA': 'PF',
  FÆRØYENE: 'FO',
  GABON: 'GA',
  GAMBIA: 'GM',
  GEORGIA: 'GE',
  GHANA: 'GH',
  GIBRALTAR: 'GI',
  GRENADA: 'GD',
  GRØNLAND: 'GL',
  GUADELOUPE: 'GP',
  GUAM: 'GU',
  GUATEMALA: 'GT',
  GUERNSEY: 'GG',
  GUINEA: 'GN',
  'GUINEA-BISSAU': 'GW',
  GUYANA: 'GY',
  HAITI: 'HT',
  'HEARD- OG MCDONALDØYENE': 'HM',
  HELLAS: 'GR',
  HONDURAS: 'HN',
  HONGKONG: 'HK',
  INDIA: 'IN',
  INDONESIA: 'ID',
  IRAK: 'IQ',
  IRAN: 'IR',
  IRLAND: 'IE',
  ISLAND: 'IS',
  'ISLE OF MAN': 'IM',
  ISRAEL: 'IL',
  ITALIA: 'IT',
  JAMAICA: 'JM',
  JAPAN: 'JP',
  JERSEY: 'JE',
  'JOMFRUØYENE AM.': 'VI',
  'JOMFRUØYENE BRIT.': 'VG',
  JORDAN: 'JO',
  KAMBODSJA: 'KH',
  KAMERUN: 'CM',
  'KAPP VERDE': 'CV',
  KAZAKHSTAN: 'KZ',
  KENYA: 'KE',
  KINA: 'CN',
  KIRGISISTAN: 'KG',
  KIRIBATI: 'KI',
  KOKOSØYENE: 'CC',
  KOMORENE: 'KM',
  'KONGO, DEN DEMOKR. REPUBL': 'CD',
  'KONGO, REPUBLIKKEN': 'CG',
  KOSOVO: 'XK',
  KROATIA: 'HR',
  KUWAIT: 'KW',
  KYPROS: 'CY',
  LAOS: 'LA',
  LATVIA: 'LV',
  LESOTHO: 'LS',
  LIBANON: 'LB',
  LIBERIA: 'LR',
  LIBYA: 'LY',
  LIECHTENSTEIN: 'LI',
  LITAUEN: 'LT',
  LUXEMBOURG: 'LU',
  MACAO: 'MO',
  MADAGASKAR: 'MG',
  MALAWI: 'MW',
  MALAYSIA: 'MY',
  MALDIVENE: 'MV',
  MALI: 'ML',
  MALTA: 'MT',
  MAROKKO: 'MA',
  MARSHALLØYENE: 'MH',
  MARTINIQUE: 'MQ',
  MAURITANIA: 'MR',
  MAURITIUS: 'MU',
  MAYOTTE: 'YT',
  MEXICO: 'MX',
  MIKRONESIAFØDERASJONEN: 'FM',
  'MINDRE STILLEHAVSØYER': 'UM',
  MOLDOVA: 'MD',
  MONACO: 'MC',
  MONGOLIA: 'MN',
  MONTENEGRO: 'ME',
  MONTSERRAT: 'MS',
  MOSAMBIK: 'MZ',
  'MYANMAR (BURMA)': 'MM',
  NAMIBIA: 'NA',
  NAURU: 'NR',
  NEDERLAND: 'NL',
  NEPAL: 'NP',
  'NEW ZEALAND': 'NZ',
  NICARAGUA: 'NI',
  NIGER: 'NE',
  NIGERIA: 'NG',
  NIUE: 'NU',
  'NORD-KOREA': 'KP',
  'NORD-MAKEDONIA': 'MK',
  'NORD-MARIANENE': 'MP',
  NORFOLKØYA: 'NF',
  NORGE: 'NO',
  'NY-KALEDONIA': 'NC',
  OMAN: 'OM',
  PAKISTAN: 'PK',
  PALAU: 'PW',
  PANAMA: 'PA',
  'PAPUA NY-GUINEA': 'PG',
  PARAGUAY: 'PY',
  PERU: 'PE',
  PITCAIRN: 'PN',
  POLEN: 'PL',
  PORTUGAL: 'PT',
  'PUERTO RICO': 'PR',
  QATAR: 'QA',
  RéUNION: 'RE',
  ROMANIA: 'RO',
  RUSSLAND: 'RU',
  RWANDA: 'RW',
  'SAINT MARTIN': 'MF',
  SALOMONØYENE: 'SB',
  'SAN MARINO': 'SM',
  'SAO TOME OG PRINCIPE': 'ST',
  'SAUDI-ARABIA': 'SA',
  SENEGAL: 'SN',
  'SENTRALAFRIKANSKE REPUBL': 'CF',
  SERBIA: 'RS',
  SEYCHELLENE: 'SC',
  'SIERRA LEONE': 'SL',
  SINGAPORE: 'SG',
  'SINT MAARTEN': 'SX',
  SLOVAKIA: 'SK',
  SLOVENIA: 'SI',
  SOMALIA: 'SO',
  SPANIA: 'ES',
  'SRI LANKA': 'LK',
  'ST BARTHELEMY': 'BL',
  'ST. LUCIA': 'LC',
  'ST.HELENA': 'SH',
  'ST.KITTS OG NEVIS': 'KN',
  'ST.PIERRE OG MIQUELON': 'PM',
  'ST.VINCENT OG GRENADINENE': 'VC',
  STORBRITANNIA: 'GB',
  SUDAN: 'SD',
  SURINAM: 'SR',
  'SVALBARD OG JAN MAYEN': 'SJ',
  SVEITS: 'CH',
  SVERIGE: 'SE',
  SWAZILAND: 'SZ',
  SYRIA: 'SY',
  'SØR-AFRIKA': 'ZA',
  'SØR-GEORGIA OG SANDWICHØYENE': 'GS',
  'SØR-KOREA': 'KR',
  'SØR-SUDAN': 'SS',
  TADSJIKISTAN: 'TJ',
  TAIWAN: 'TW',
  TANZANIA: 'TZ',
  TCHAD: 'TD',
  THAILAND: 'TH',
  TOGO: 'TG',
  TOKELAU: 'TK',
  TONGA: 'TO',
  'TRINIDAD OG TOBAGO': 'TT',
  TSJEKKIA: 'CZ',
  TUNISIA: 'TN',
  TURKMENISTAN: 'TM',
  'TURKS/CAICOSØYENE': 'TC',
  TUVALU: 'TV',
  TYRKIA: 'TR',
  TYSKLAND: 'DE',
  UGANDA: 'UG',
  UKRAINA: 'UA',
  UNGARN: 'HU',
  URUGUAY: 'UY',
  USA: 'US',
  USBEKISTAN: 'UZ',
  VANUATU: 'VU',
  VATIKANSTATEN: 'VA',
  VENEZUELA: 'VE',
  'VEST-SAHARA': 'EH',
  'VEST-SAMOA': 'WS',
  VIETNAM: 'VN',
  'WALLIS/FUTUNAØYENE': 'WF',
  YEMEN: 'YE',
  ZAMBIA: 'ZM',
  ZIMBABWE: 'ZW',
  ØSTERRIKE: 'AT',
  'ØST-TIMOR': 'TL',
  ÅLAND: 'AX',
};
