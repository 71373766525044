export enum EarlyAccessEnum {
  ALL = 'ALL',
  ROLE_EDIT = 'ROLE_EDIT',
  TRANSFER_ACCOUNT = 'TRANSFER_ACCOUNT',
  RECURSIVE_PERMANENT_DELETE = 'RECURSIVE_PERMANENT_DELETE',
  ZIP_UPLOAD = 'ZIP_UPLOAD',
  STYLES = 'STYLES',
  ALTINN = 'ALTINN',
  ORGBRAIN_SUPPORT = 'ORGBRAIN_SUPPORT',
  OPENAI = 'OPENAI',
  ORG_AUTH = 'ORG_AUTH',
  INVESTORIMPORT = 'INVESTORIMPORT',
  REDUCED_RELOAD = 'REDUCED_RELOAD',
  AGENDA_ITEMS_COMMENTS = 'AGENDA_ITEMS_COMMENTS',
  BOARDMATCH = 'BOARDMATCH',
  HIDE_SIGNING_TEXT = 'HIDE_SIGNING_TEXT',
}

// if deprecated grant access to all users
export const deprecatedEarlyAccessMap: { [key in EarlyAccessEnum]?: boolean } = {
  [EarlyAccessEnum.ALL]: false,
  [EarlyAccessEnum.ROLE_EDIT]: false,
  [EarlyAccessEnum.TRANSFER_ACCOUNT]: false,
  [EarlyAccessEnum.RECURSIVE_PERMANENT_DELETE]: false,
  [EarlyAccessEnum.ZIP_UPLOAD]: false,
  [EarlyAccessEnum.STYLES]: false,
  [EarlyAccessEnum.ALTINN]: false,
  [EarlyAccessEnum.ORGBRAIN_SUPPORT]: true,
  [EarlyAccessEnum.OPENAI]: true,
  [EarlyAccessEnum.INVESTORIMPORT]: false,
  [EarlyAccessEnum.REDUCED_RELOAD]: false,
  [EarlyAccessEnum.BOARDMATCH]: true,
  [EarlyAccessEnum.HIDE_SIGNING_TEXT]: false,
};

export type EarlyAccessMap = { [key in EarlyAccessEnum]: boolean };
