import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';

import { BisnodeData } from '@orgbrain/lib-admin';

import { BrregHelperService } from './brreg-helper.service';

@Injectable({
  providedIn: 'root',
})
export class BisnodeService {
  constructor(public http: HttpClient, private brregService: BrregHelperService) {}

  getSuggestions(org_id): Observable<BisnodeData> {
    return from(this.brregService.getOrgByOrgId(org_id)) as any;
  }
}
