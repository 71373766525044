import { Injectable } from '@angular/core';
import { environment } from '@orgbrain/environment';
import { hexString2String, printClassName } from '@orgbrain/lib-data';

// Make  sure that this is loaded before anything that depends on the params
declare var CryptoJS;
@Injectable({
  providedIn: 'root',
})
export class LandingParamsService {
  private params: URLSearchParams;
  emailInvalid = false;
  email: any;
  docView;
  path: string[];
  locationHref = '';
  private searchString: string;
  dying;
  constructor() {
    printClassName(' ---> ', this);
    this.locationHref = window.location.href;
    this.parseSearchString(window.location.search);
  }

  getSearchString() {
    return this.searchString;
  }

  parseSearchString(search: string) {
    this.searchString = search;
    const str = decodeURIComponent(search);
    this.path = window.location.pathname.split('/');
    this.params = new URLSearchParams(str);
    this.docView = window.location.pathname === '/view';

    const handle = this.params.get('handle');
    if (handle) {
      // Decrypt
      const str = hexString2String(handle);

      var bytes = CryptoJS.AES.decrypt(str, environment.EMAIL_QUERY_SECRET);

      this.email = bytes.toString(CryptoJS.enc.Utf8);
      if (this.params.get('email')) {
        console.error(' Did not expect email and handle to be set');
      }
    }
  }

  getOrgId() {
    return this.path[3];
  }

  get(key) {
    if (key === 'email' && this.email) {
      if (this.emailInvalid) {
        return null;
      }
      return this.email;
    }

    let val = this.params.get(key);
    if (!val) return val;
    if (key === 'user') return val.replace(/ /g, '+').trim().toLowerCase();
    if (key === 'email') {
      return val.replace(/ /g, '+').trim().toLowerCase();
    }
    if (key === 'organizationid' || key === 'organizationId') {
      if (val?.length > 24) {
        val = val.slice(0, 24);
      }
    }
    return val;
  }

  getOrgIdFromRoute(route) {
    if (!route) {
      return null;
    }
    const toks = route.split('/');
    const ii = toks.findIndex((tok) => tok === 'organizations');
    if (ii >= 0) {
      return toks[ii + 1];
    } else {
      return null;
    }
  }
}
