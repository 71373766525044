<div
  orgbrainStyle
  class="orgbrain-style d-flex justify-between items-center"
  [ngStyle]="{ 'padding-bottom': padding_bottom }"
>
  <div
    mat-dialog-title
    class="mb-0 black-text d-flex justify-content-between align-items-center mt-0 pb-0 pr-0"
    style="font-weight: 500; max-width: 90%"
  >
    <ng-content> </ng-content>
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <div class="text-breaker" [ngClass]="titleCss">{{ title }}</div>
    <orgbrain-info-box-open-button
      *ngIf="helpTextId"
      [moduleId]="helpTextId"
      style="margin-left: 4px; margin-bottom: -6px"
    >
    </orgbrain-info-box-open-button>
    <a
      *ngIf="helpurl"
      [href]="helpurl"
      target="_blank"
      rel="noopener"
      mat-icon-button
      matTooltip="help"
      style="vertical-align: super"
    >
      <mat-icon>help_outline</mat-icon>
    </a>

    <span style="flex: 1"></span>
  </div>
  <ng-container *ngIf="dialogRef || closeIcon">
    <div *ngIf="dialogRef === 'lazy'" class="close-button" mat-dialog-close>
      <button mat-icon-button>
        <mat-icon>
          {{ closeIcon || 'close' }}
        </mat-icon>
      </button>
    </div>
    <div *ngIf="dialogRef !== 'lazy'" class="close-button">
      <button mat-icon-button (click)="closeClicked()">
        <mat-icon class="icon-press">{{ closeIcon || 'close' }}</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
<div class="d-grid grid-cols-3 mbx-4 mtx-2" *ngIf="helpText && helpTextId">
  <orgbrain-info-box
    [beginClosedState]="beginClosedState"
    [moduleId]="helpTextId"
    class="col-span-3"
  >
    <div>{{ helpText }}</div>
  </orgbrain-info-box>
</div>
