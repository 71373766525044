<div mat-dialog-title class="dialog-title-with-close-button">
  <h1 style="margin-top: 16px">
    {{ data.title }}
  </h1>
</div>

<mat-dialog-content class="info-box">
  <p [innerHtml]="trustedHtml" class="mat-body"></p>

  <mat-spinner *ngIf="!data.progress"></mat-spinner>
  <ng-container *ngIf="data.progress">
    {{ data.progress.loaded }}<span *ngIf="data.progress.total">/{{ data.progress.total }}</span>
  </ng-container>

  {{ data?.progress?.error }}
  <!-- <div innerHTML="data.progress.error" style="background-color: bisque;"></div> -->

  <mat-dialog-actions align="end" *ngIf="data.progress && data.progress.error">
    <button
      mat-raised-button
      color="primary"
      class="action-button"
      cdkFocusInitial
      mat-dialog-close
      id="confirm_dialog_button"
    >
      {{ data.dismiss || 'Dismiss' }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
